.loader-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.954); 
  z-index: 9999;
}

.card {
  --bg-color: #212121;
  background-color: var(--bg-color);
  padding: 1rem 2rem;
  border-radius: 1.25rem;
  width: 48%;
  height: 10%;
  justify-content: center;
  align-items: center;
  align-self: center;
}

.loader {
  color: rgb(255, 255, 255);
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 25px;
  box-sizing: content-box;
  height: 40px;
  padding: 10px 10px;
  display: flex;
  border-radius: 8px;
}

.words {
  overflow: hidden;
  position: relative;
  height: 40px; /* Varsayılan yükseklik */
}

.words::after {
  content: "";
  position: absolute;
  inset: 0;
  background: linear-gradient(
    var(--bg-color) 10%,
    transparent 30%,
    transparent 70%,
    var(--bg-color) 90%
  );
  z-index: 20;
}

.word {
  display: block;
  height: 100%;
  padding-left: 6px;
  color: #956afa;
  animation: spin_4991 9s infinite;
}

@keyframes spin_4991 {
  0% {
    transform: translateY(0%);
  }
  14.28% {
    transform: translateY(0%);
  }
  28.56% {
    transform: translateY(-100%);
  }
  42.84% {
    transform: translateY(-200%);
  }
  57.12% {
    transform: translateY(-300%);
  }
  71.40% {
    transform: translateY(-400%);
  }
  85.68% {
    transform: translateY(-500%);
  }
  100% {
    transform: translateY(-600%);
  }
}

@media (max-width: 768px) {
  .loader {
    font-size: 18px; /* Mobilde daha küçük yazı boyutu */
  }

  .card {
    padding: 1rem;
    width: 80%;
  }

  .words {
    height: 30px; /* Mobilde daha küçük bir yükseklik */
  }

  .word {
    font-size: 16px; /* Mobilde daha küçük yazı boyutu */
  }
}
