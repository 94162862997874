.song-recommendation {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: linear-gradient(0deg, rgb(255, 96, 54), rgb(253, 38, 122)) no-repeat;
    overflow: hidden;
}

.card {
    position: absolute;
    width: 300px;
    height: 400px;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
    transition: transform 0.5s;
}

.card img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.deneme {
    overflow: hidden;
}

.card-back {
    background: rgba(0, 0, 0, 0.44);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
}

.background {
    background: rgb(63,94,251);
    background: radial-gradient(circle, rgba(63,94,251,1) 0%, rgba(252,70,107,1) 100%);
}